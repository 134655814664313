export const karirDatabase = [
  // {
  //   id: 1,
  //   posisi: "Pejabat Eksekutif Audit Internal",
  //   kualifikasi: [
  //     "Pria/Wanita",
  //     "Memiliki pengalaman minimal 2 tahun sebagai Audit Intern Bank.",
  //     "Berjiwa Independent.",
  //     "Memiliki loyalitas dan komitmen yang kuat serta siap bekerja dibawah tekanan.",
  //     "Memiliki kemampuan komunikasi dan koordinasi yang baik serta mampu bekerja dalam tim.",
  //   ],
  //   benefit: ["-"],
  //   penempatan: "Pasar Baru Jakarta Pusat",
  //   picture: "./karir_rekruitmen/Audit.jpg",
  // },
  {
    id: 2,
    posisi: "Marketing Funding Bank",
    kualifikasi: [
      "Pria/Wanita",
      "Berpenampilan menarik.",
      "Memiliki pengalaman minimal 2 tahun sebagai marketing lending Bank.",
      "Memiliki kemampuan komunikasi dan persuasi yang baik.",
      "Memiliki network/database yang luas dan terampil menggunakan media sosial.",
    ],
    benefit: ["Remunerasi menarik", "Insentif performa/Bonus"],
    penempatan: "Pasar Baru Jakarta Pusat",
    picture: "./karir_rekruitmen/low_funding.jpeg",
  },
  // {
  //   id: 3,
  //   posisi: "Marketing Funding Bank",
  //   kualifikasi: [
  //     "Pria/Wanita",
  //     "Berpenampilan menarik.",
  //     "Memiliki pengalaman minimal 2 tahun sebagai marketing lending Bank.",
  //     "Memiliki kemampuan komunikasi dan persuasi yang baik.",
  //     "Memiliki network/database yang luas dan terampil menggunakan media sosial.",
  //   ],
  //   benefit: ["Remunerasi menarik", "Insentif performa/Bonus"],
  //   penempatan: "Pasar Baru Jakarta Pusat",
  //   picture: "./karir_rekruitmen/Funding.jpg",
  // },
  {
    id: 4,
    posisi: "Marketing Lending Bank",
    kualifikasi: [
      "Pria/Wanita",
      "Pendidikan min D3/S1 Akuntansi",
      "Usia maks 30 tahun",
      "Memiliki pengalaman 1-2 tahun di bidang accounting perbankan",
      "Menguasai standar akuntansi keuangan perbankan",
      "Memiliki pengetahuan mengenai peraturan perbankan terkait pelaporan dan akuntansi keuangan",
      "Mampu menganalisis laporan keuangan bank",
      "Terampil dalam menggunakan Ms. Office khususnya Excel dan software akuntansi ⁠yang baik",
    ],
    benefit: ["Permanent Staff"],
    penempatan: "Pasar Baru Jakarta Pusat",
    picture: "./karir_rekruitmen/low_lending.jpeg",
  },
  {
    id: 5,
    posisi: "Kredit Analis",
    kualifikasi: [
      "Pendidikan min. D3 Akuntansi/ Manajemen/ Keuangan atau bidang yang relevan",
      "Pengalaman dibidang analisa kredit perbankan minimal 2 tahun",
      "Keterampilan analisis data yang baik, termasuk kemampuan untuk mengevaluasi laporan keuangan dan membuat proyeksi",
      "Mampu bekerja dalam tim dan berinteraksi dengan berbagai pihak",
      "Memiliki integritas, komunikatif, dan teliti",
      "Dapat bergabung segera",
      "Bersedia ditempatkan di Head Office ",
    ],
    benefit: ["Permanent Staff"],
    penempatan: "Pasar Baru Jakarta Pusat",
    picture: "./karir_rekruitmen/LokerAnalisKredit.jpg",
  },
];
