export const API_all = "http://localhost:127/produk";

// API LIVE Online

export const FINAL_API_Simpanan = "https://apiservice.banksembada.com/simpanan";

export const FINAL_API_Pinjaman = "https://apiservice.banksembada.com/pinjaman";

export const FINAL_API_Sdb = "https://apiservice.banksembada.com/sdb";

export const FINAL_API_Kemitraan =
  "https://apiservice.banksembada.com/kemitraan";

export const FINAL_API_PengaduanNasabah =
  "https://apiservice.banksembada.com/pengaduan";
